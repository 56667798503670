import { SimulationCtx } from '@/contexts/Simulation/SimulationCtx';
import { AppDispatch, RootState } from '@/store';
import { update, updateMultipleDependency } from '@/store/storeSlice';
import { OperationResource, TreeItem } from '@/store/types';
import {
  Button,
  ButtonIcon,
  Select,
  SelectOption,
  Stack,
  Typography,
} from '@data-products-and-ai/react-components';
import { produce } from 'immer';
import { useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

export type TFormOperationsResources = {
  RESOURCE_ID: TreeItem;
  RESOURCE_NAME: TreeItem;
  RESOURCE_SHIFT_CALENDAR: TreeItem;
  RESOURCE_PRIORITY: TreeItem;
  OPERATION_ID: TreeItem;
  OPERATIONS_RESOURCES_ID: TreeItem;
};

/**
 * FormOperationsResources Component
 * Manages the form for editing an operation resources details within a simulation scenario
 * Filters and sorts the operation resources based on the selected operation
 */
const FormOperationsResources = () => {
  const { simulationParams, setSimulationParams } = useContext(SimulationCtx);
  const dispatch: AppDispatch = useDispatch();
  const selectedScenario = useSelector((state: RootState) =>
    state.store.Simulation.scenarios.find((item) => item.is_selected),
  );

  const [removedResources, setRemovedResources] = useState<
    TFormOperationsResources[]
  >([]);

  const filteredObjects: OperationResource[] = selectedScenario
    ? selectedScenario.data.operations_resources.allIds
        .map((id) => selectedScenario?.data.operations_resources.byId[id])
        .filter((obj) => obj.OPERATION_ID.value === simulationParams.formItem)
        .sort(
          (a, b) =>
            parseInt(a.RESOURCE_PRIORITY.value) -
            parseInt(b.RESOURCE_PRIORITY.value),
        )
    : [];

  const parseResource = (item: OperationResource) => {
    const resource =
      selectedScenario?.data.resources.byId[item.RESOURCE_ID.value];

    if (!resource) {
      return {
        RESOURCE_ID: { value: '', originalValue: '' },
        RESOURCE_NAME: { value: '', originalValue: '' },
        RESOURCE_SHIFT_CALENDAR: { value: '', originalValue: '' },
        RESOURCE_PRIORITY: { value: '', originalValue: '' },
        OPERATION_ID: { value: '', originalValue: '' },
        OPERATIONS_RESOURCES_ID: { value: '', originalValue: '' },
      };
    }

    return {
      RESOURCE_ID: {
        value: resource.RESOURCE_ID.value,
        originalValue: resource.RESOURCE_ID.value,
      },
      RESOURCE_NAME: {
        value: resource.RESOURCE_NAME.value,
        originalValue: resource.RESOURCE_NAME.value,
      },
      RESOURCE_SHIFT_CALENDAR: {
        value:
          selectedScenario?.data.shift_calendar.byId[
            resource.RESOURCE_SHIFT_CALENDAR_ID.value
          ]?.RESOURCE_SHIFT_CALENDAR.value || '',
        originalValue:
          selectedScenario?.data.shift_calendar.byId[
            resource.RESOURCE_SHIFT_CALENDAR_ID.value
          ]?.RESOURCE_SHIFT_CALENDAR.value || '',
      },
      RESOURCE_PRIORITY: {
        value: item.RESOURCE_PRIORITY.value,
        originalValue: item.RESOURCE_PRIORITY.value,
      },
      OPERATION_ID: {
        value: item.OPERATION_ID.value || '',
        originalValue: item.OPERATION_ID.value || '',
      },
      OPERATIONS_RESOURCES_ID: {
        value: item.OPERATIONS_RESOURCES_ID.value || '',
        originalValue: item.OPERATIONS_RESOURCES_ID.value || '',
      },
    };
  };
  const initialAvailableResources = selectedScenario
    ? selectedScenario.data.operations_resources.allIds
        .map((id) => selectedScenario?.data.operations_resources.byId[id])
        .filter((obj) => obj.OPERATION_ID.value !== simulationParams.formItem)
        .sort(
          (a, b) =>
            parseInt(a.RESOURCE_PRIORITY.value) -
            parseInt(b.RESOURCE_PRIORITY.value),
        )
        .map((item) => parseResource(item))
    : [];

  const [availableResources, setAvailableResources] = useState<
    TFormOperationsResources[]
  >(initialAvailableResources);

  const initalResources = filteredObjects.map((item: OperationResource) => {
    return parseResource(item);
  });

  useEffect(() => {
    setForm(initalResources);
  }, [simulationParams.drawerOpen]);

  const [form, setForm] = useState<TFormOperationsResources[]>([]);
  const [isEditing, setIsEditing] = useState(false);

  const handleResources = () => {
    const c = form.map((item: TFormOperationsResources, idx: number) => {
      const isLastItem = idx === form.length - 1;
      const borderBottomStyle = isLastItem
        ? {}
        : { borderBottom: 'solid 1px #dadada' };

      if (
        !item.RESOURCE_NAME &&
        !item.RESOURCE_PRIORITY &&
        !item.RESOURCE_SHIFT_CALENDAR
      )
        return;
      return (
        <div
          key={idx}
          style={{
            display: 'grid',
            gridTemplateColumns: '1fr 1fr 0.5fr 0.2fr',
            padding: 5,
            gap: 20,
            ...borderBottomStyle,
          }}
        >
          <div>{item.RESOURCE_NAME.value}</div>
          <div>{item.RESOURCE_SHIFT_CALENDAR.value}</div>
          <div style={{ paddingRight: 10, textAlign: 'center' }}>
            {!isEditing ? (
              item.RESOURCE_PRIORITY.value
            ) : (
              <Select
                size="small"
                defaultValue={item.RESOURCE_PRIORITY.value}
                onChange={({ target }) =>
                  handleSelectPriority(target.value, idx)
                }
              >
                {form.map((_, idx) => {
                  const number = idx + 1;
                  return (
                    <SelectOption
                      key={number}
                      value={number.toString()}
                      label={number.toString()}
                      selected={
                        number.toString() === item.RESOURCE_PRIORITY.value
                      }
                    />
                  );
                })}
              </Select>
            )}
          </div>
          {isEditing && (
            <div>
              <ButtonIcon
                icon="IconClose"
                onClick={() => handleRemoveResource(idx)}
              />
            </div>
          )}
        </div>
      );
    });

    const handleRemoveResource = (idx: number) => {
      setRemovedResources((prev) => {
        return [
          ...prev,
          { ...form[idx], OPERATION_ID: { value: '', originalValue: '' } },
        ];
      });
      setForm((prev) => {
        const newForm = [...prev];
        newForm.splice(idx, 1);
        return newForm;
      });
      setAvailableResources((prev) => {
        const newAvailableResources = [...prev];
        const resource = form[idx];
        if (resource) {
          newAvailableResources.splice(0, 0, resource);
        }
        return newAvailableResources;
      });
    };

    const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
      setIsEditing(false);

      const finalForm = form.concat(removedResources);

      dispatch(
        updateMultipleDependency({
          key: 'OPERATIONS_RESOURCES_ID',
          area: 'operations_resources',
          content: finalForm.map((item) => ({
            RESOURCE_ID: item.RESOURCE_ID,
            OPERATION_ID: item.OPERATION_ID,
            RESOURCE_PRIORITY: item.RESOURCE_PRIORITY,
            OPERATIONS_RESOURCES_ID: item.OPERATIONS_RESOURCES_ID,
          })),
        }),
      );

      e.preventDefault();
      console.log('submit');
    };

    const handleCancel = () => {
      setIsEditing(false);
      setSimulationParams(
        produce((draft) => {
          draft.formItem = undefined;
          draft.formArea = undefined;
          draft.drawerOpen = false;
        }),
      );

      setForm(initalResources);
    };

    const handleAddResource = (idx: number) => {
      const resource = availableResources[idx];
      if (!resource) return;
      const removedIdx =  removedResources.findIndex(
        (item) => item.RESOURCE_ID.value === resource.RESOURCE_ID.value,
      )
      if (removedIdx !== -1) {
        removedResources.splice(removedIdx, 1);
      }
      setForm((prev) => {
        return [
          ...prev,
          {
            ...resource,
            OPERATION_ID: {
              value: simulationParams.formItem || '',
              originalValue: simulationParams.formItem || '',
            },
          },
        ];
      });
      setAvailableResources((prev) => {
        const newAvailableResources = [...prev];
        newAvailableResources.splice(idx, 1);
        return newAvailableResources;
      });
    };

    const handleSelectPriority = (e: string, idx: number) => {
      setForm((prev) => {
        const newForm = [...prev];
        newForm[idx].RESOURCE_PRIORITY.value = e;
        return newForm;
      });
    };

    return (
      <form onSubmit={handleSubmit} style={{ height: '100%' }}>
        <div
          style={{ display: 'flex', flexDirection: 'column', height: '100%' , overflowY: 'auto'}}
        >
          <div style={{ flex: 1 }}>
            <div
              style={{
                display: 'grid',
                gridTemplateColumns: '1fr 1fr 0.5fr 0.2fr',
                padding: 5,
                gap: 20,

                borderBottom: 'solid 1px #dadada',
                fontSize: 11,
                fontWeight: 'bold',
              }}
            >
              <div>Resource</div>
              <div>Shift</div>
              <div style={{ textAlign: 'center' }}>Priority</div>
            </div>
            {c}
            {isEditing && (
              <div>
                <div style={{ marginBlock: 20, textAlign: 'center' }}>
                  <Typography tag="textsmall" color="#8a00e5">
                    Available Resources
                  </Typography>
                </div>
                {availableResources.map(
                  (resource: TFormOperationsResources, idx: number) => {
                    const isLastItem = idx === availableResources.length - 1;
                    const borderBottomStyle = isLastItem
                      ? {}
                      : { borderBottom: 'solid 1px #dadada' };

                    return (
                      <div
                        key={idx}
                        style={{
                          display: 'grid',
                          gridTemplateColumns: '1fr 0fr',
                          padding: 5,
                          gap: 20,
                          ...borderBottomStyle,
                        }}
                      >
                        <div
                          style={{
                            display: 'grid',
                            gridTemplateColumns: '1fr 1fr 0.5fr',
                            gap: 20,
                            opacity: 0.5,
                          }}
                        >
                          <div>{resource.RESOURCE_NAME.value}</div>
                          <div>{resource.RESOURCE_SHIFT_CALENDAR.value}</div>
                          <div
                            style={{ paddingRight: 10, textAlign: 'center' }}
                          >
                            1
                          </div>
                        </div>
                        <div>
                          <ButtonIcon
                            icon="IconAdd"
                            onClick={() => handleAddResource(idx)}
                          />
                        </div>
                      </div>
                    );
                  },
                )}
              </div>
            )}
          </div>
          <div
            style={{
              paddingTop: 10,
              paddingBottom: 10,
            }}
          ></div>
          <div
            style={{
              position: 'sticky',
              bottom: 0,
              marginTop: 30,
              backgroundColor: 'white',
              paddingBottom: 5,
              paddingTop: 20,
              borderTop: 'solid 1px #ededed',
            }}
          >
            <Stack direction="row" distribute="space-around">
              <Button
                buttonType="button"
                type="primaryOutline"
                width="200px"
                onClick={() => handleCancel()}
              >
                Cancel
              </Button>
              {!isEditing ? (
                <Button
                  buttonType="button"
                  type="primaryNoShadow"
                  width="200px"
                  onClick={() => setIsEditing(true)}
                >
                  Edit
                </Button>
              ) : (
                <Button
                  buttonType="submit"
                  type="primaryNoShadow"
                  width="200px"
                >
                  Update
                </Button>
              )}
            </Stack>
          </div>
        </div>
      </form>
    );
  };

  if (!simulationParams.formItem) return 'Not found';

  return handleResources();
};

export default FormOperationsResources;
