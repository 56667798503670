import { SimulationCtx } from '@/contexts/Simulation/SimulationCtx';
import { AppDispatch, RootState } from '@/store';
import {
  createItem,
  deleteItem,
  update,
} from '@/store/storeSlice';
import { Personnel, Skills, TreeItem } from '@/store/types';
import {
  Button,
  Checkbox,
  Input,
  Label,
  Stack,
  Typography,
} from '@data-products-and-ai/react-components';
import { produce } from 'immer';
import { useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

export type TFormPersonnel = {
  PERSON_ID: TreeItem;
  PERSON_NAME: TreeItem;
  PERSON_SKILLS: TreeItem<TFormPersonnelSkill[]>;
  SITE_ID: TreeItem;
};

export type TFormPersonnelSkill = {
  PERSON_SKILL_PK: TreeItem;
  SKILL_ID: TreeItem;
  SKILL: TreeItem;
  isSelected?: boolean;
};

/**
 * FormPersonnel Component
 * Manages the form for editing a distinct personnel details within a simulation scenario
 * Handles form submission, input changes, and closing the form drawer
 *
 * @returns {JSX.Element} - The rendered component
 */
const FormPersonnel = () => {
  const { simulationParams, setSimulationParams } = useContext(SimulationCtx);
  const dispatch: AppDispatch = useDispatch();
  const selectedScenario = useSelector((state: RootState) =>
    state.store.Simulation.scenarios.find((item) => item.is_selected === true),
  );

  const InitialFormProducts: TFormPersonnel = {
    PERSON_ID: { value: '', originalValue: '' },
    PERSON_NAME: { value: '', originalValue: '' },
    PERSON_SKILLS: { value: [], originalValue: [] },
    SITE_ID: { value: '', originalValue: '' },
  };

  const [deletedSkills, setDeletedSkills] = useState<TFormPersonnelSkill[]>([]);
  const [addedSkills, setAddedSkills] = useState<TFormPersonnelSkill[]>([]);
  const [form, setForm] = useState(InitialFormProducts);

  const WipArea = 'personnel';

  const selectedItem =
    simulationParams.formItem && selectedScenario
      ? selectedScenario.data[WipArea].byId[simulationParams.formItem]
      : null;

  const generateId = (item: Skills) => {
    if (!selectedScenario) return '';
    const lastId = selectedScenario.data.personnel_skills.allIds
      .filter((itemId: string) => {
        const personSkill = selectedScenario.data.personnel_skills.byId[itemId];
        return personSkill.SKILL_ID.value === item.SKILL_ID.value;
      })
      .pop();
    if (lastId) {
      const parts = lastId.split('-')[0].split('_');
      parts[4] = (parseInt(parts[4], 10) + 1).toString();
      const lastIdNumber = parts.join('_');
      return lastIdNumber + '-' + item.SKILL_ID.value;
    } else {
      const id = item.SKILL_ID.value.split('SK_ID_')[1];
      return (
        'PER_ID_' + id.split('_')[0].concat('_1') + '-' + item.SKILL_ID.value
      );
    }
  };

  const generatePersonnelSkill = (skill: TFormPersonnelSkill) => {
    if (!selectedItem) return;
    return {
      PERSON_SKILL_PK: {
        value: skill.PERSON_SKILL_PK.value,
        originalValue: skill.PERSON_SKILL_PK.originalValue,
        type: 'id',
      },
      PERSON_ID: {
        value: form.PERSON_ID.value,
        originalValue: form.PERSON_ID.originalValue,
        type: 'id',
      },
      SKILL_ID: {
        value: skill.SKILL_ID.value,
        originalValue: skill.SKILL_ID.originalValue,
        type: 'id',
      },
      SKILL: {
        value: skill.SKILL.value,
        originalValue: skill.SKILL.value,
        type: 'text',
      },
      PERSON_SKILL_PRIORITY: {
        value: '',
        originalValue: undefined,
        type: 'text',
      },
      SITE: {
        value: selectedItem.SITE.value,
        originalValue: selectedItem.SITE.originalValue,
        type: 'text',
      },
      SITE_ABBREVIATION: {
        value:
          selectedScenario?.data.sites.byId[selectedItem.SITE_ID.value]
            .SITE_ABBREVIATION.value,
        originalValue:
          selectedScenario?.data.sites.byId[selectedItem.SITE_ID.value]
            .SITE_ABBREVIATION.value,
        type: 'text',
      },
      SITE_ID: {
        value: selectedItem.SITE_ID.value,
        originalValue: selectedItem.SITE_ID.originalValue,
        type: 'id',
      },
      WIP_ID: {
        value: selectedItem.WIP_ID.value,
        originalValue: selectedItem.WIP_ID.originalValue,
        type: 'id',
      },
      key: '',
      title: '',
      is_wip: true,
      is_hidden: false,
      is_disabled: false,
      is_deleted: false,
      is_open: false,
      is_selected: false,
      is_selectable: false,
      is_match: false,
      children: [],
      is_clicked: false,
      parent: '',
    };
  };

  const skills: TFormPersonnelSkill[] =
    selectedScenario?.data.skills.allIds.map((itemId: string) => {
      const item = selectedScenario.data.skills.byId[itemId];

      const filteredSkills =
        selectedScenario?.data.personnel_skills.allIds.find(
          (itemId: string) => {
            const personSkill =
              selectedScenario.data.personnel_skills.byId[itemId];
            return (
              personSkill.SKILL_ID.value === item.SKILL_ID.value &&
              personSkill.PERSON_ID.value === simulationParams.formItem
            );
          },
        );

      let personSkillId: string;

      if (filteredSkills) {
        personSkillId =
          selectedScenario.data.personnel_skills.byId[filteredSkills]
            .PERSON_SKILL_PK.value;
      } else {
        personSkillId = generateId(item);
      }

      return {
        PERSON_SKILL_PK: {
          value: personSkillId,
          originalValue: personSkillId,
        },
        SKILL_ID: {
          value: item.SKILL_ID.originalValue ?? '',
          originalValue: item.SKILL_ID.originalValue ?? '',
        },
        SKILL: {
          value: item.SKILL.value ?? '',
          originalValue: item.SKILL.value ?? '',
        },
      };
    }) || [];

  const handleInputChange = (
    event: React.ChangeEvent<
      HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement
    >,
    property?: string,
  ) => {
    const { id, value, checked } = event.target as HTMLInputElement & {
      id: string;
      value: string;
      type: string;
      checked: boolean;
    };
    const updateForm = (id: keyof TFormPersonnel, newValue: unknown) => {
      setForm((prevForm) => ({
        ...prevForm,
        [id]: {
          ...prevForm[id],
          value: newValue,
        },
      }));
    };

    if (property === 'PERSON_SKILLS') {
      const skill =
        form.PERSON_SKILLS.value.find(
          (item: TFormPersonnelSkill) => item.SKILL_ID.value === value,
        ) || '';
      if (!skill) return;

      if (!checked) {
        setDeletedSkills((prev) => [...prev, skill]);
        setAddedSkills((prev) =>
          prev.filter((item) => item.SKILL_ID.value !== value),
        );
      } else {
        setAddedSkills((prev) => [...prev, skill]);
        setDeletedSkills((prev) =>
          prev.filter((item) => item.SKILL_ID.value !== value),
        );
      }

      const skills = form.PERSON_SKILLS.value.map(
        (item: TFormPersonnelSkill) => {
          if (item.SKILL_ID.value === value) {
            item.isSelected = checked;
          }
          return item;
        },
      );
      updateForm(property, skills);
    } else {
      updateForm(id as keyof TFormPersonnel, value);
    }
  };

  const handleCloseDrawer = () => {
    setSimulationParams(
      produce((draft) => {
        draft.formItem = undefined;
        draft.formArea = undefined;
        draft.drawerOpen = false;
      }),
    );

    setForm(InitialFormProducts);
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    if (!selectedItem || !selectedScenario) return;
    e.preventDefault();

    dispatch(
      update({
        id: simulationParams.formItem,
        area: WipArea,
        content: form.PERSON_NAME,
      }),
    );

    for (const skill of deletedSkills) {
      dispatch(
        deleteItem({
          area: 'personnel_skills',
          id: skill.PERSON_SKILL_PK.value,
        }),
      );
    }
    for (const skill of addedSkills) {
      const personSkill = generatePersonnelSkill(skill);
      if (personSkill) {
        dispatch(
          createItem({
            key: 'PERSON_SKILL_PK',
            area: 'personnel_skills',
            content: personSkill,
          }),
        );
      }
    }
    handleCloseDrawer();
  };

  useEffect(() => {
    if (!selectedItem || !selectedScenario) return;

    const formToChange: TFormPersonnel = {
      PERSON_ID: {
        value: selectedItem.PERSON_ID.value,
        originalValue: selectedItem.PERSON_ID.value,
      },
      PERSON_NAME: {
        value: selectedItem.PERSON_NAME.value,
        originalValue: selectedItem.PERSON_NAME.value,
      },
      PERSON_SKILLS: {
        value: getSelectedSkills(selectedItem),
        originalValue: getSelectedSkills(selectedItem),
      },

      SITE_ID: {
        value: selectedItem.SITE_ID.value,
        originalValue: selectedItem.SITE_ID.value,
      },
    };
    setForm(formToChange);
  }, [selectedItem]);

  const getSelectedSkills = (selectedItem: Personnel) => {
    return skills.map((item: TFormPersonnelSkill) => {
      selectedScenario?.data.personnel_skills.allIds.forEach(
        (itemId: string) => {
          const personSkill =
            selectedScenario.data.personnel_skills.byId[itemId];

          if (personSkill.PERSON_ID.value === selectedItem.PERSON_ID.value) {
            if (personSkill.SKILL_ID.value === item.SKILL_ID.value) {
              item.isSelected = true;
              return;
            }
          }
        },
      );
      return item;
    });
  };

  if (!selectedItem || !selectedScenario) return;

  const formMargin = 30;

  return (
    <form onSubmit={handleSubmit}>
      <div
        style={{
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          overflowY: 'auto',
          overflowX: 'hidden',
          padding: 20,
          paddingBottom: 100,
        }}
      >
        <div
          style={{
            marginBottom: formMargin,
            marginTop: formMargin / 2,
          }}
        >
          <div>
            <h5 style={{ paddingBottom: 20, color: '#8a00e5' }}>
              {' '}
              Edit Person Info
            </h5>
            <Label tag="textsmall_strong">Site </Label>
            <Typography tag="textsmall">
              {
                selectedScenario.data.sites.byId[selectedItem.SITE_ID.value]
                  .SITE_ABBREVIATION.value
              }
            </Typography>
          </div>
        </div>
        <Label tag="textsmall_strong">Person Name</Label>
        <Input
          size="small"
          id="PERSON_NAME"
          defaultValue={form.PERSON_NAME.value}
          onChange={(event) => handleInputChange(event)}
        ></Input>
        <Label marginTop={formMargin} tag="textsmall_strong">
          Skills
        </Label>
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          {form.PERSON_SKILLS.value.map((item: TFormPersonnelSkill) => {
            return (
              <Checkbox
                key={item.SKILL_ID.value}
                id={item.SKILL_ID.value}
                label={item.SKILL.value}
                value={item.SKILL_ID.value}
                isChecked={item.isSelected}
                onChange={(event) => {
                  handleInputChange(event, 'PERSON_SKILLS');
                }}
              />
            );
          })}
        </div>
        <div
          style={{
            position: 'fixed',
            bottom: 0,
            marginTop: 30,
            backgroundColor: 'white',
            paddingBottom: 20,
            paddingTop: 20,
            width: `calc(100% - 40px)`,
            borderTop: 'solid 1px #ededed',
          }}
        >
          <Stack direction="row" distribute="space-around">
            <Button
              buttonType="button"
              type="primaryOutline"
              width="200px"
              onClick={handleCloseDrawer}
            >
              Cancel
            </Button>
            <Button buttonType="submit" type="primaryNoShadow" width="200px">
              Update
            </Button>
          </Stack>
        </div>
      </div>
    </form>
  );
};

export default FormPersonnel;
